<template>
  <v-container fill-height justify-center>
    <div>
      <v-card max-width="350" class="pa-4 text-center" flat>
        <v-card-text>
          <v-img
            max-height="200"
            contain
            alt="dealpartner"
            :src="require('@/assets/logo.png')"
            class="ma-auto"
          />
          <div class="text-h6">Sign in</div>
          <div class="mb-5">
            Welcome to the
            <span class="primary--text">dealPartner.lk</span> Admin Dashboard.
            Sign in with your Email
          </div>
          <v-form ref="loginForm" @submit.prevent>
            <v-text-field
              label="Email"
              v-model="email"
              :rules="[rules.required, rules.email]"
              outlined
              dense
              @keyup.enter="sendOtp"
            ></v-text-field>
            <v-btn
              depressed
              block
              class="primary text-capitalize"
              @click="sendOtp"
              :loading="loading"
            >
              Next
            </v-btn>
          </v-form>
          <div class="mt-5 text-caption">
            This secure portal is exclusively for authorized administrators to
            manage and oversee all aspects of
            <span class="primary--text">dealPartner.lk</span>.
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import { sentOTP } from "@/services/authService";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    email: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
    },
    loading: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goNext() {
      this.$router.push({ name: "PageAuthOTP" });
    },
    async sendOtp() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        let sentOTPReturn = await sentOTP({ email: this.email });
        this.loading = false;
        if (sentOTPReturn == "success") {
          this.goNext();
        }
      }
    },
  },
};
</script>

<style>
.back {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}
</style>